<template>
  <page-wrapper title="АРХИВНЫЕ ТЕНДЕРЫ">
    <filters :filters="filters" :options="options" archive @change="onChange" />
    <tender-table archive />
    <i-pagination :params="getPagination" @changed="onChangePage" />
    <tender-modal />
  </page-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import Filters from '@/components/TablesFormsFilters/Filters'
import TenderTable from '@/components/Tender/TenderTable'
import IPagination from '@/components/Ui/Pagination'
import TenderModal from '@/components/Tender/TenderModal'
import { filterOptionsTenderArchive } from './constants'

export default {
  name: 'Tenders',
  components: { PageWrapper, Filters, IPagination, TenderTable, TenderModal },
  data() {
    return {
      filters: {
        'per-page': 20,
        status: '',
        any: ''
      },
      page: 1,
      options: filterOptionsTenderArchive
    }
  },
  computed: {
    ...mapGetters('tenders', ['getPagination'])
  },
  created() {
    this.getTenders()
  },
  methods: {
    ...mapActions('tenders', ['FETCH']),
    getTenders() {
      const settings = {
        ...this.filters,
        [this.isBidder ? 'show_participated' : 'show_own']: 1,
        page: this.page,
        active: 0,
        sort: '-date_start'
      }
      return this.FETCH(settings)
    },
    onChange(key, val) {
      this.filters[key] = val
      this.getTenders()
    },
    onChangePage(page) {
      this.page = page
      this.getTenders()
    }
  },
  beforeDestroy() {
    clearInterval(this.requestInterval)
  }
}
</script>
